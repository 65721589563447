<template>
  <div class="enterpriseManual">
    <en-table-layout :tableData="tableData.data" :loading="loading">
      <template slot="toolbar">
        <el-form-item>
          <el-input v-model.trim="params.searchKey" size="small" placeholder="请输入关键字进行搜索" clearable>
            <el-select style="width: 100px;" v-model="params.key_words" slot="prepend">
              <el-option label="企业名称" value="shop_name"></el-option>
              <el-option label="企业账号" value="member_name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-button @click="getSearchData" type="primary" size="small">搜索</el-button>
      </template>
      <template slot="table-columns">
        <el-table-column prop="shop_id" label="企业ID" width="150" />
        <el-table-column prop="uname" label="企业账号" width="150" />
        <el-table-column prop="shop_name" label="企业名称" show-overflow-tooltip />
        <el-table-column prop="balance_account" label="当前余额" width="150" />
        <el-table-column
          prop="level_duetime"
          label="剩余时间"
          width="150"
          :formatter="level_duetimeFormatter"
        />
        <el-table-column prop="level_name" label="企业等级" width="150" />
        <el-table-column label="操作" width="80" fixed="right">
          <template slot-scope="{row}">
            <el-button size="mini" type="primary" @click="rechargeHandle(row)">充值</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="params.page_no"
        :page-sizes="MixinPageSizes"
        :page-size="params.page_size"
        :layout="MixinTableLayout"
        :total="tableData.data_total"
        background
      ></el-pagination>
    </en-table-layout>
    <!--    充值弹窗-->
    <el-dialog-x-dialog title="充值操作" :visible.sync="rechargeVisible" width="35%">
      <el-form
        :model="rechargeForm"
        :rules="rechargeRules"
        ref="rechargeRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="企业名称" prop="name">
          <span v-text="rechargeForm.shop_name"></span>
        </el-form-item>
        <el-form-item label="充值金额" prop="money">
          <el-input
            style="width:320px;"
            v-model="rechargeForm.money"
            placeholder="请输入要充值的金额"
            @input="changePrice"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注信息" prop="remark">
          <el-input
            style="width:320px;"
            type="textarea"
            v-model="rechargeForm.remark"
            placeholder="请输入备注信息，不超过100字。"
          ></el-input>
        </el-form-item>
        <el-form-item label="短信验证码" prop="code">
          <el-input style="width:200px;" v-model="rechargeForm.code" placeholder="请输入验证码"></el-input>
          <el-button
            style="width:120px;"
            type="primary"
            @click="getCode"
            :disabled="getCodeTxt !== '获取验证码'"
          >{{getCodeTxt}}{{getCodeTxt !== '获取验证码'?'s':''}}</el-button>
        </el-form-item>
      </el-form>
      <div style="display: flex;justify-content: space-between;padding-bottom: 22px">
        <span></span>
        <span>
          <el-button size="small" @click="rechargeVisible = false">取 消</el-button>
          <el-button size="small" type="primary" @click="rechargeConfirm('rechargeRef')">确 定</el-button>
        </span>
      </div>
    </el-dialog-x-dialog>
  </div>
</template>

<script>
import * as API_account from "@/api/account";
import { Foundation } from "@/../ui-utils";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";
import xDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";

export default {
  name: "enterpriseManual",
  components: { EnTableLayout },
  data() {
    var checkPrice = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("充值金额不能为0"));
      }
      if (Number(value) > 1000) {
        callback(new Error("请输入1000以内的充值金额"));
      }
      callback();
    };
    return {
      getCodeTxt: "获取验证码",
      clickStatus: true,
      loading: false,
      // 充值弹窗
      rechargeVisible: false,
      // 充值表单
      rechargeForm: {
        shop_id: "",
        shop_name: "",
        money: "",
        remark: "",
        code: ""
      },
      rechargeRules: {
        money: [{ required: true, validator: checkPrice }],
        remark: [{ max: 100, message: "长度在不超过 100 个字符" }],
        code: [{ required: true, message: "短信验证码为必填项" }]
      },
      params: {
        key_words: "shop_name",
        searchKey: "",
        shop_type: 3,
        page_no: 1,
        page_size: 20
      },
      tableData: {
        data: [],
        data_total: 0
      },
      timer: null
    };
  },
  mounted() {
    this.GET_EnterpriseList();
    sessionStorage.setItem("getCodeList", JSON.stringify([]));
  },
  methods: {
    getSearchData() {
      this.params.page_no = 1;
      this.GET_EnterpriseList();
    },
    rechargeConfirm(formName) {
      // if (!this.clickStatus) return this.$message.warning("请勿重复提交充值");
      this.clickStatus = false;
      console.log(this.clickStatus);
      this.$refs[formName].validate(valid => {
        console.log(this.clickStatus, valid);
        if (valid) {
          API_account.addCompanyNew(this.rechargeForm).then(() => {
            let getCodeList = JSON.parse(sessionStorage.getItem("getCodeList"));
            getCodeList.forEach((elem, index) => {
              if(elem.shop_id === this.rechargeForm.shop_id) {
                return getCodeList.splice(index, 1)
              }
            })
            sessionStorage.setItem("getCodeList", JSON.stringify(getCodeList));
            this.$message.success("充值成功");
            this.getCodeTxt = "获取验证码";
            clearInterval(this.timer);
            this.rechargeVisible = false;
            this.GET_EnterpriseList();
          });
        } else {
          console.log(valid);
          return false;
        }
      });
      setTimeout(() => {
        this.clickStatus = true;
      }, 2000);
    },
    getCode() {
      console.log(this.rechargeForm);
      API_account.smscode({ shop_id: this.rechargeForm.shop_id }).then(res => {
        let getCodeList = JSON.parse(sessionStorage.getItem("getCodeList"));
        this.getCodeTxt = 600;
        getCodeList.push({
          shop_id: this.rechargeForm.shop_id,
          text: this.getCodeTxt,
          time: Date.parse(new Date())
        });
        sessionStorage.setItem("getCodeList", JSON.stringify(getCodeList));
        this.timer = setInterval(() => {
          this.getCodeTxt--;
          if (this.getCodeTxt <= 0) {
            this.getCodeTxt = "获取验证码";
            clearInterval(this.timer);
          }
        }, 1000);
      });
    },
    changePrice(e) {
      if (e[0] === "-") {
        this.rechargeForm.money =
          "-" + e.slice(1).match(/^\d*(\.?\d{0,2})/g)[0];
      } else {
        this.rechargeForm.money = e.match(/^\d*(\.?\d{0,2})/g)[0];
      }
    },
    rechargeHandle(data) {
      let getCodeList = JSON.parse(sessionStorage.getItem("getCodeList"));
      this.getCodeTxt = "获取验证码";
      clearInterval(this.timer);
      getCodeList.forEach(elem => {
        if (elem.shop_id === data.shop_id) {
          if (elem.time + 600000 > Date.parse(new Date())) {
            this.getCodeTxt =
              (elem.time + 600000 - Date.parse(new Date())) / 1000;
            this.timer = setInterval(() => {
              this.getCodeTxt--;
              if (this.getCodeTxt <= 0) {
                this.getCodeTxt = "获取验证码";
                clearInterval(this.timer);
              }
            }, 1000);
          } else {
            this.getCodeTxt = "获取验证码";
          }
        }
      });
      // if(this.getCodeTxt === "获取验证码") 
      this.rechargeForm.shop_id = data.shop_id;
      this.rechargeForm.shop_name = data.shop_name;
      this.rechargeForm.money = "";
      this.rechargeForm.remark = "";
      this.rechargeForm.code = "";
      this.rechargeVisible = true;
    },
    GET_EnterpriseList() {
      let params = { ...this.params };
      switch (this.params.key_words) {
        case "shop_name":
          params.shop_name = this.params.searchKey;
          break;
        case "member_name":
          params.member_name = this.params.searchKey;
          break;
        default:
          break;
      }
      delete params.key_words;
      delete params.searchKey;
      API_account.adminShops(params).then(res => {
        this.tableData = res;
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_EnterpriseList();
    },
    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_EnterpriseList();
    },
    // 格式剩余天数
    level_duetimeFormatter(row) {
      var date1 = new Date(); //开始时间
      var date3 = row.level_duetime * 1000 - date1.getTime(); //时间差的毫秒数zhi
      var days = Math.floor(date3 / (24 * 3600 * 1000));
      if (days < 0) return "0天";
      return days + "天";
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
